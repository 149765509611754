
import { treeData as data } from "../../../custom/ExpandableContractGrid/CeqGridData"

const expandableColumnTreeMap = (data) => {
    console.log("tree:::DATA>>>", data)
    const tree = {}
    tree["parent_source_name"] = data?.final_output_variable_name;
    tree["parent_formula"] = data?.final_output_formula.formula;
    tree["output"] = data?.final_output_formula.output;
    tree["children"] = [];

    if (data?.final_output_formula?.inputs?.length > 0) {

        data.final_output_formula.inputs.forEach((item, index) => {
            let childrenElement = {};
            let nodeName = Object.keys(item)[0];

            data.decisionOutputs.forEach((item, index) => {
                childrenElement["source_name"] = nodeName;

                if (item?.variable === nodeName) {
                    childrenElement = {
                        ...childrenElement,
                        decisionId: item?.decisionId,
                        decisionName: item?.decisionName,
                        decisionVersion: item?.decisionVersion,
                        decisionOutput: item?.decisionOutput,
                        ruleIndex: item?.ruleIndex,
                        rulePlan: item?.rulePlan
                    }
                    if (!("inputs" in item)) {
                        childrenElement["children"] = []
                    }
                    if (!("output" in item)) {
                        childrenElement["output"] = item?.decisionOutput
                    } else {
                        childrenElement["output"] = item?.output
                    }


                } else if (item?.feel_expression_formula?.variable === nodeName) {
                    let grandChildrens = item?.feel_expression_formula?.inputs.map(item => {
                        const key = Object.keys(item)[0];
                        return {
                            "source_name": key,
                            "output": item[key]
                        };
                    });
                    childrenElement = {
                        ...childrenElement,
                        decisionId: item?.decisionId,
                        decisionName: item?.decisionName,
                        decisionVersion: item?.decisionVersion,
                        decisionOutput: item?.decisionOutput,
                        ruleIndex: item?.ruleIndex,
                        rulePlan: item?.rulePlan,
                        formula: item?.feel_expression_formula.formula,
                        output: item?.feel_expression_formula.output,
                        children: grandChildrens
                    }
                }
            })
            tree.children.push(childrenElement)
            console.log("tree:::childrenElement>>>", childrenElement)
        })
    }

    console.log("tree:::", tree)
    return tree;
}
export default expandableColumnTreeMap;