// package imports

// project imports
import "../treeNode.css"
import PopoverCard from "./PopoverCard";


const TreeNode = ({ node }) => {
    return (
        <div className="tree-node">
            {node.parent_source_name ? (
                <div className="node-content-parent">
                    <div>
                        {node.parent_source_name}
                        {/* <PopoverCard node={node} /> */}
                    </div>
                    <div>
                        <span className="node-content-parent-label">Formula</span> - {node.parent_formula}
                    </div>
                    <div>
                        <span className="node-content-parent-label">Value</span> - {node.output}
                    </div>
                </div>
            ) : (
                <div className="node-content-children">
                    {/* <div className={`node-content-${node.source_name}`}> */}
                    <div>
                        {node.source_name}
                        {/* <PopoverCard node={node} /> */}
                    </div>
                    {node?.formula &&
                        <div>
                            <span className="node-content-parent-label">Formula</span> - {node.formula}</div>
                    }
                    <div>
                        <span className="node-content-parent-label">Value</span> - {node.output}
                    </div>
                </div>
            )}

            {node.children?.length > 0 ? (
                <div className="children">
                    {node.children.map((child, index) => (
                        <TreeNode
                            key={index}
                            node={child}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default TreeNode;